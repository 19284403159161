<template>
    <div>
        <v-container fluid class="pt-0 mt-0">
            <HeaderAzul titulo="Meus" subtitulo="Cursos"/>
        </v-container>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="6" xl="4" v-for="(c, i) in cursos" :key="i">
                    <v-card :to="`/curso/completo/${c.id_curso}/${c.id_turma}`"><!-- :color="c.percentual_conclusao == 100 ? '#FFD600' :'#FFFFFF'" -->
                        <v-card-text class="pt-3 mt-0">
                            <v-row>
                                <v-col cols="7">
                                    <!--<v-progress-circular style="position: absolute; top:-10px; right:-10px; background-color: #005FAB; border-radius: 50%; box-shadow: 1px 1px 1px #555;" :rotate="360" :size="62" :width="15" :value="c.percentual_conclusao" color="#FFD600"><strong style="font-size: 11px;">{{c.percentual_conclusao}}%</strong></v-progress-circular>-->
                                    <h2>{{c.disciplina}}<br /><strong>{{c.curso}}</strong></h2>
                                </v-col>
                                <v-col cols="5" style="text-align: right;" v-if="!usuarioSessao.embed">
                                    <v-chip x-small color="primary">{{c.aula_total_conteudo_presente}}/{{c.aula_total_conteudo}} {{porcentagem(c.aula_total_conteudo, c.aula_total_conteudo_presente)}}% aproveitamento</v-chip><br />
                                    <v-chip x-small color="#FFD601">{{c.aula_total_presente}}/{{c.aula_total}} {{porcentagem(c.aula_total, c.aula_total_presente)}}% presença</v-chip><br />
                                    <v-chip x-small color="#990000" dark>{{c.aula_total - c.aula_total_presente}}/{{c.aula_total}} {{porcentagem(c.aula_total, c.aula_total - c.aula_total_presente)}}% ausência</v-chip><br />
                                </v-col>
                            </v-row>
                            <v-divider class="my-3"/>
                            <span style="font-size: 18px;">{{c.turma}}</span>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import {mapState} from "vuex";
import axios from "axios";
import HeaderAzul from "@/Views/Estrutura/HeaderAzul";
export default {
    name: "Cursos",
    components: {HeaderAzul},
    data: () => ({
        cursos : [
            // {id_curso: 1,disciplina: 'Inglês', curso : 'Energy', percentual_conclusao : 100}
        ]
    }),
    computed : {
        ...mapState(['apiUrl', 'usuarioSessao'])
    },
    methods : {
        listar() {
            return axios.post(`${this.apiUrl}turma/listar`, {
                embed : this.usuarioSessao.embed || false,
                id_turma : parseInt(this.usuarioSessao.id_turma)
            }).then( (res) => {
                this.cursos = res.data.lista.map((v) => {
                    let pc = 0
                    if (v.aula_concluida_total != 0 && v.aula_total != 0) {
                        pc = this.numero((v.aula_concluida_total / v.aula_total) * 100, 0)
                    } else {
                        pc = 0;
                    }
                    return {
                        ...v,
                        percentual_conclusao : pc
                    }
                });
            });
        }
    },
    activated() {
        this.listar();
    }
}
</script>

<style scoped>
    h2 {font-size: 42px; font-weight: lighter; line-height: 110%; color: #00436F;}
    h2 strong {font-weight: bold;}
</style>